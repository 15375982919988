/* 基础布局 */
.admin-page {
  min-height: 100vh;
  background-color: #f5f5f5;
}

/* 登录页面样式 */
.login-container {
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
  padding: 2.5rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slideUp 0.4s ease;
}

.login-container h1 {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.75rem;
  font-weight: 600;
}

/* 管理界面布局 */
.admin-layout {
  min-height: 100vh;
}

/* 顶部导航栏样式 */
.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #1a1a1a;
  color: white;
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 1000;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
}

/* 顶部导航栏标题 */
.top-nav h1 {
  margin: 0;
  margin-left: 24px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

/* 侧边栏切换按钮 */
.sidebar-toggle {
  width: 60px;
  height: 60px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-toggle:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* 侧边栏样式 */
.sidebar {
  width: 250px;
  background: #1a1a1a;
  color: white;
  transition: all 0.3s ease;
  height: calc(100vh - 60px);
  position: fixed;
  left: 0;
  top: 60px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar.closed {
  width: 0;
  overflow: hidden;
}

/* 当侧边栏打开时，调整切换按钮位置 */
.sidebar.open .sidebar-toggle {
  left: 210px;
}

.sidebar-header {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

/* 侧边栏导航区域 */
.sidebar-nav {
  flex: 1; /* 占据剩余空间 */
  padding: 1rem 0;
  overflow-y: auto;
}

/* 导航项目样式 */
.nav-item {
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 50px;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  font-size: 14px;
}

.nav-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.nav-item.active {
  background: #4285f4;
}

.nav-item svg {
  margin-right: 12px;
  width: 16px;
  opacity: 0.7;
}

/* 退出登录按钮容器 */
.sidebar-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto; /* 推到底部 */
}

/* 退出登录按钮样式 */
.logout-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 24px;
  color: #ef4444;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.logout-button svg {
  margin-right: 12px;
  width: 16px;
  opacity: 0.7;
}

.logout-button:hover {
  background: rgba(239, 68, 68, 0.1);
}

/* 主容器样式调整 */
.main-container {
  display: flex;
  margin-top: 60px;
  min-height: calc(100vh - 60px);
  margin-left: 250px; /* 为侧边栏留出空间 */
  transition: all 0.3s ease;
}

.main-container.sidebar-closed {
  margin-left: 0;
}

/* 主内容区域 */
.main-content {
  flex: 1;
  padding: 2rem;
  padding-left: 60px; /* 为固定的切换按钮留出空间 */
  background: #f5f5f5;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.main-content.full-width {
  padding-left: 60px;
}

/* 内容区域样式 */
.content-section {
  background: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* 表单容器 */
.form-container {
  margin-bottom: 2rem;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

/* 表单组样式 */
.form-group {
  margin-bottom: 2rem;
  display: grid;
  align-items: center;
}

/* 表单标签样式 */
.form-group label {
  color: #4b5563;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

/* 输入框样式 */
.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.875rem;
}

/* 两列布局的表单组 */
.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
}

/* 主内容区域的操作按钮容器 */
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 3rem;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

/* 主内容区域的按钮基础样式 */
.action-buttons .save-button,
.action-buttons .cancel-button {
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  min-width: 100px;
  height: 36px;
  flex: 1;
  max-width: 180px;
}

/* 保存按钮样式 */
.action-buttons .save-button {
  background-color: #4285f4;
  color: white;
  border: none;
}

/* 返回按钮样式 */
.action-buttons .cancel-button {
  background-color: #f1f3f4;
  color: #3c4043;
  border: 1px solid #dadce0;
}

.action-buttons .cancel-button:hover {
  background-color: #e8eaed;
}

/* 对话框样式 */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.dialog-content {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  animation: dialogFadeIn 0.3s ease;
}

.dialog-content h2 {
  margin: 0 0 1.5rem 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  text-align: center;
}

.dialog-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.dialog-button-yes, .dialog-button-no {
  padding: 0.75rem 2rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  min-width: 100px;
}

.dialog-button-yes {
  background: #ef4444;
  color: white;
}

.dialog-button-no {
  background: #e5e7eb;
  color: #4b5563;
}

.dialog-button-yes:hover {
  background: #dc2626;
  transform: translateY(-1px);
}

.dialog-button-no:hover {
  background: #d1d5db;
  transform: translateY(-1px);
}

/* 对话框动画 */
@keyframes dialogFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 夜间模式适配 */
@media (prefers-color-scheme: dark) {
  .dialog-content {
    background: #2d2d2d;
    color: #e0e0e0;
  }

  .dialog-content h2 {
    color: #e0e0e0;
  }

  .dialog-button-no {
    background: #3d3d3d;
    color: #e0e0e0;
  }

  .action-buttons {
    background: #2d2d2d;
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .admin-layout {
    flex-direction: column;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  }

  .sidebar.closed {
    width: 0;
  }

  .sidebar-toggle {
    display: block;
  }

  .main-content {
    margin-left: 0;
    padding: 1rem;
  }

  .main-content.full-width {
    width: 100%;
  }

  .content-section {
    padding: 1rem;
  }

  .action-buttons {
    justify-content: center;
    padding-left: 1rem;
  }

  .save-button, .cancel-button {
    width: 100%;
    min-width: unset;
  }
}

/* 加载动画 */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-overlay::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 输入框样式 */
.password-input {
  width: 100%;
  padding: 0.875rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
}

.password-input:focus {
  border-color: #3b82f6;
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

/* 按钮容器 */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

/* 按钮样式 */
.login-button, .return-button {
  width: 100%;
  padding: 0.875rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: center;
}

.login-button {
  background: #3b82f6;
  color: white;
  border: none;
}

.login-button:hover {
  background: #2563eb;
  transform: translateY(-1px);
}

.return-button {
  background: #e5e7eb;
  color: #4b5563;
  border: none;
}

.return-button:hover {
  background: #d1d5db;
  transform: translateY(-1px);
}

/* 记住我样式 */
.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  user-select: none;
}

.remember-me input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* 自定义复选框 */
.checkmark {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 2px solid #e5e7eb;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.remember-me:hover input ~ .checkmark {
  border-color: #3b82f6;
}

.remember-me input:checked ~ .checkmark {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

/* 创建勾号 */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.remember-me input:checked ~ .checkmark:after {
  display: block;
}

.remember-me label {
  font-size: 0.875rem;
  color: #4b5563;
  margin-left: 8px;
  cursor: pointer;
}

/* 夜间模式适配 */
@media (prefers-color-scheme: dark) {
  .checkmark {
    background-color: #3d3d3d;
    border-color: #4d4d4d;
  }

  .remember-me:hover input ~ .checkmark {
    border-color: #3b82f6;
  }

  .remember-me input:checked ~ .checkmark {
    background-color: #3b82f6;
    border-color: #3b82f6;
  }

  .remember-me label {
    color: #a0a0a0;
  }
}

/* 动画效果 */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* 夜间模式适配 */
@media (prefers-color-scheme: dark) {
  .login-container {
    background: #2d2d2d;
  }

  .login-container h1 {
    color: #e0e0e0;
  }

  .password-input {
    background: #3d3d3d;
    border-color: #4d4d4d;
    color: #e0e0e0;
  }

  .password-input:focus {
    border-color: #3b82f6;
  }

  .return-button {
    background: #3d3d3d;
    color: #e0e0e0;
  }
}

/* 响应式设计 */
@media (max-width: 480px) {
  .login-container {
    padding: 1.5rem;
  }

  .login-container h1 {
    font-size: 1.5rem;
  }
}
